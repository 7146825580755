import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Congratulations from './Congratulations';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



export default function Answer({artistName, songTitle, handleCorrectAnswer}) { 
    const [answer, setAnswer] = useState("");
    const [similarityScore, setSimilarityScore] = useState(null);
    const [correctAnswer, setCorrectAnswer] = useState(false);
    const [incorrectAnswer, setIncorrectAnswer] = useState(false);
    

    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
      };

    const CheckAnswer = async (answer) => {
        console.log("artist name: ", artistName)
        console.log("song title: ", songTitle)
        console.log("answer: ", answer)
        try {
            // Make a POST request to your backend endpoint
            const response = await axios.post('http://localhost:9090/api/answer', {
                artistName: artistName,
                songTitle: songTitle,
                answer: answer
            });
            console.log(response.data.checkAnswer)
            console.log(response.data.similarity)
            if(response.data.checkAnswer === "Correct"){
                setCorrectAnswer(true);
                setSimilarityScore(response.data.similarity);
            } else{
                setIncorrectAnswer(true);
            }
            
        } catch (error) {
            console.error('Error sending question to backend:', error);
        }
    };



    return (
        <div className="container" >
            <div className="row">
                <h3 style={{"color":"white"}}>Try writing any lyrics you know:</h3>
            </div>
            <br/>
            <div className="row" style={{display: 'block', margin: '0 auto', width: '500px',}}>
            {incorrectAnswer && <h5 style={{"color":"white"}}>Incorrect answer, try adding more lyrics!</h5>}
                <input type="text" value={answer} onChange={handleAnswerChange}></input>
            </div>
            <br/>
            <div className="row" >
                <div className="col"></div>
                
                <Button onClick={() => CheckAnswer(answer)} style={{"width":"250px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Submit</Button>
                <div className="col"></div>
            </div>
            
            {correctAnswer && <Congratulations artistName={artistName} songTitle={songTitle} similarityScore={similarityScore} handleCorrectAnswer={handleCorrectAnswer}/>}
        </div>
    );
}
