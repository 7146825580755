import logo from './logo.svg';
import './App.css';
import SongPlayer from './SongPlayer';
import Answer from './Answer';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';

function App() {

  const [showPlayer, setShowPlayer] = useState(false);
  const [artistName, setArtistName] = useState(null);
  const [songTitle, setSongTitle] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);

  const GetRandomSong = async () => {
      try {
          // Make a POST request to your backend endpoint
          const response = await axios.get('http://localhost:9090/api/question', {
          });
          setArtistName(response.data.result.artistName);
          setSongTitle(response.data.result.songTitle);
          setPreviewURL(response.data.result.previewURL);
          setShowPlayer(true);
      } catch (error) {
          console.error('Error sending question to backend:', error);
      }
  }

  const StartGame = () => {
    GetRandomSong();
  }

  const handleCorrectAnswer = () => {
    setShowPlayer(false);
    setArtistName(null);
    setSongTitle(null);
    setPreviewURL(null);
    GetRandomSong();
  }

  return (
    <div className="App" style={{"backgroundColor": "#0f0f0f"}}>
        <div className='row' style={{"paddingTop":"5%", "color":"white"}}>
          <h1>It starts with..</h1>
        </div>
        <div className='row' style={{"paddingTop":"5%"}}>
          <div className='col'></div>
          {!showPlayer && <Button onClick={StartGame} style={{"width":"350px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Start Game</Button>}
          {showPlayer && 
            <SongPlayer previewURL={previewURL} />}
            <div className='col'></div>
        </div>
        <div className='row' style={{"paddingTop":"5%"}}>
          {showPlayer &&
          <Answer artistName={artistName} songTitle={songTitle} handleCorrectAnswer={handleCorrectAnswer}/>}
        </div>
    </div>
  );
}

export default App;
