import React, { useEffect, useRef, useState  } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';



export default function SongPlayer({previewURL}) { 
    
    const [step, setStep] = useState(0);

    const audioRef = useRef(null);

    const handlePlay = (time) => {
        // Play the audio
        audioRef.current.play();

        // Stop the audio after 0.1 seconds
        setTimeout(() => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset the audio to the beginning
        }, time); // 100 = 0.1 seconds
    };

    const handlePause = () => {
        audioRef.current.pause();
    };


    return (
        <div className="container">

            <div>
                <Button onClick={() => handlePlay(100)} style={{"width":"350px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Play audio for 0.1 Seconds</Button>
                <audio ref={audioRef} src={previewURL} />
            </div>
            {step > 0 &&
            <div>
                <Button onClick={() => handlePlay(300)} style={{"width":"350px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Play audio for 0.3 Seconds</Button>
                <audio ref={audioRef} src={previewURL} />
            </div>}
            {step > 1 &&
            <div>
                <Button onClick={() => handlePlay(500)} style={{"width":"350px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Play audio for 0.5 Seconds</Button>
                <audio ref={audioRef} src={previewURL} />
            </div>}
            {step > 2 &&
            <div>
                <Button onClick={() => handlePlay(1000)} style={{"width":"350px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Play audio for 1 Second</Button>
                <audio ref={audioRef} src={previewURL} />
            </div>}
            {step > 3 &&
            <div>
                <Button onClick={() => handlePlay(30000)} style={{"width":"350px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Play full length audio</Button>
                <audio ref={audioRef} src={previewURL} />
                <br/>
                <br/>
                <Button onClick={handlePause} style={{"width":"250px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Stop playing</Button>
            </div>}
            <br/>
            {step < 4 && <Button onClick={() => setStep(step + 1)} style={{"width":"250px", "borderColor":"black", "backgroundColor":"#8c8c8c","color":"white"}}>Next Step</Button>}
        </div>
    );
}
