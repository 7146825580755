import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button} from 'react-bootstrap';
import axios from 'axios';
import spotifyLogo from './images/Spotify_Logo_CMYK_Black.png';

export default function Congratulations({artistName, songTitle, similarityScore, handleCorrectAnswer}) {
    
    const [previewURL, setPreviewURL] = useState("");
    const [albumImage, setAlbumImage] = useState("");
    const [artistLink, setArtistLink] = useState("");
    const [trackLink, setTrackLink] = useState("");

    const [showCongratulations, setShowCongratulations] = useState(false);

    const audioRef = useRef(null);

    useEffect(() => {
        GetSongInfo();
    }, []);

    const GetSongInfo = async (answer) => {
        try {
            // Make a POST request to your backend endpoint
            const response = await axios.post('http://localhost:9090/api/songinfo', {
                artistName: artistName,
                songTitle: songTitle
            });
            console.log(response)
            setPreviewURL(response.data.songResult.previewURL);
            setAlbumImage(response.data.songResult.albumImage);
            setArtistLink(response.data.songResult.artistLink);
            setTrackLink(response.data.songResult.trackLink);
            setShowCongratulations(true);
            audioRef.current.play();
        } catch (error) {
            console.error('Error sending question to backend:', error);
        }
    };
    

    const handleClose = () => {
        handleCorrectAnswer();
        audioRef.current.pause();
    }

    return (
        <Modal show={showCongratulations} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Congratulations!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <img style={{ width: '470px', height: '470px' }} src={albumImage}></img>
            <h4 >{songTitle} - {artistName}</h4>
            <h5>Your answer matched the song with a {similarityScore}% similarity score!</h5>
            <audio ref={audioRef} src={previewURL} />
            
            <div className='row'>
                <h5>Listen to the song:</h5>
            </div>
            <div className='row'>
                <div className='col'>
                    <img style={{ width: '100%', height: '100%' }} src={spotifyLogo}></img></div>
                <div className='col'>
                    <a href={artistLink}><Button className="btn btn-success" style={{ width: '200px', height: '35px'}}>Check out artist</Button></a>
                    <a href={trackLink}><Button className="btn btn-success" style={{ width: '200px', height: '35px'}}>Check out the song</Button></a></div>
            </div>
            
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                Try another song
            </Button>
            </Modal.Footer>
      </Modal>
    );
}